import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Site } from '../models/site.model';

export const SitesActions = createActionGroup({
    source: 'Sites',
    events: {
        'Load Sites': emptyProps(),
        'Load Sites Success': props<{ sites: Site[] }>(),
        'Load Sites Failure': props<{ message: any }>(),
    },
});
